import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Card,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  Radio,
  Row,
  Space,
} from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import {
  cpqRetailUrl,
  dateFormatWithoutTime,
  fieldLevelDisabled,
  formValidatorRules,
} from '../../../common/utils';
import DatePickerComponent from '../../../components/DatePickerComponent';
import InputComponent from '../../../components/InputComponent';

const { required, slug, requiredWhiteSpaceAllowed, url } = formValidatorRules;
const GeneralForm = (props) => {
  const {
    setShowDatePicker,
    industriesData,
    brandsData,
    showDatePicker,
    policyUrlDisable,
    isEdit = false,
    initialValue,
    editableFields = [],
  } = props;
  const {
    state: { globalDateFormat, isSponsor },
  } = useContext(AppContext);
  const onCheckChange = ({ target: { checked } }) => {
    setShowDatePicker(checked);
  };

  const userDomain =
    initialValue?.[
      !isSponsor && !initialValue?.sponsorName?.length
        ? 'tenantSubDomain'
        : 'sponsorSubDomain'
    ];

  const subDomainContent = (
    <div className="sub-domain-tooltip">
      This is the name at the end of the link that you share with prospective
      clients.
      <Divider />
      <div className="guidelines-heading">
        <b>Guidelines :</b>
      </div>
      <ol>
        <li>
          The subdomain can contain only small characters, numbers and
          hyphen(-).
        </li>
        <li>Space or Special Characters are not allowed.</li>
        <li> The subdomain name must be a unique one.</li>
      </ol>
    </div>
  );
  return (
    <Card className="card-wrapper">
      <div className="setting-content-heading">
        <h5>GENERAL</h5>
        <span className="panel-description">
          General settings of this questionnaire
        </span>
      </div>
      <Row className="setting-content">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            name="title"
            rules={[
              { ...required, message: 'Please Enter Questionnaire Name' },
            ]}
          >
            <InputComponent
              disabled={fieldLevelDisabled({
                isSponsorRecord: initialValue?.sponsorName?.length > 0,
                fieldName: 'title',
                editableFields,
              })}
              allowClear
              placeholder="Add Questionnaire Name *"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            name="link"
            label="Subdomain"
            className="label-with-tooltip"
            tooltip={{
              title: subDomainContent,
              overlayClassName: 'subdomain-info',
              icon: <QuestionCircleOutlined />,
            }}
            rules={[{ ...required, message: 'Please Enter SubDomain' }, slug]}
          >
            <InputComponent
              className="subdomain-input"
              addonBefore={`${cpqRetailUrl(userDomain, isSponsor)}/`}
              allowClear
              placeholder="Add subdomain"
              disabled={fieldLevelDisabled({
                isSponsorRecord: initialValue?.sponsorName?.length > 0,
                fieldName: 'link',
                editableFields,
              })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <div className="setting-content-heading">
        <h5>INDUSTRY</h5>
        <span className="panel-description">
          Select the industry for this questionnaire
        </span>
      </div>
      <Row className="setting-content">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item name="industryId">
            <Radio.Group>
              <Space direction="vertical">
                {map(industriesData, (item) => (
                  <Radio
                    className="common-radio"
                    value={item?.id}
                    disabled={isEdit}
                    key={item?.id}
                  >
                    <div>
                      <span className="industry-label">{item?.label}</span>
                      <br />
                      {map(item?.lobSaData, (lob, index) => (
                        <span key={lob?.id} className="lob-label">
                          {lob?.label}
                          {item?.lobSaData?.length > 0 &&
                            index !== item?.lobSaData?.length - 1 &&
                            ', '}
                        </span>
                      ))}
                    </div>
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <span className="panel-description">
        For a more precise quote, kindly choose the appropriate ROQ PATH for
        each service type from the "My Project" page.
      </span>
      <Divider />
      <div className="setting-content-heading">
        <h5>RETAILER</h5>
        <span className="panel-description">
          Retailer of this questionnaire
        </span>
      </div>
      <Row className="setting-content">
        {brandsData?.brands?.count > 0 ? (
          <Form.Item
            name="brandId"
            className="brand-select fill-width"
            rules={[{ ...required, message: 'Please Select Retailer' }]}
          >
            <Radio.Group
              className="fill-width"
              disabled={fieldLevelDisabled({
                isSponsorRecord: initialValue?.sponsorName?.length > 0,
                fieldName: 'brandId',
                editableFields,
              })}
            >
              <Row gutter={[16, 16]}>
                {map(brandsData?.brands?.data, (item) => (
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    xxl={6}
                    key={item?.id}
                  >
                    <Radio
                      className="common-radio setting-brands-radio"
                      value={item?.id}
                      aria-label={`BrandRadio ${item?.id}`}
                    >
                      <img
                        src={item?.logo?.url || placeholderImage}
                        alt="brand-img"
                        onError={(e) => {
                          e.target.src = placeholderImage;
                        }}
                        className="list-img image-contain"
                        width={24}
                        height={24}
                      />
                      {item?.name}
                    </Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
        ) : (
          <Empty
            className="no-data-image"
            image={Empty?.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Row>
      <Divider />
      <div className="setting-content-heading">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h5>Policy Links</h5>
            <span className="panel-description">
              Set privacy links and terms of use links
            </span>
            <h5 className="mt-16">Terms Of Use</h5>
          </Col>
          <Col xs={18} sm={18} md={18} lg={18} xl={12} xxl={12}>
            <Form.Item
              name={['termsOfUse', 'termsOfUseUrl']}
              label="Terms Of Use Url"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please Enter URL',
                },
                url,
              ]}
            >
              <InputComponent
                disabled={
                  policyUrlDisable?.termsOfUse ||
                  fieldLevelDisabled({
                    isSponsorRecord: initialValue?.sponsorName?.length > 0,
                    fieldName: 'termsOfUse',
                    editableFields,
                  })
                }
                placeholder="Enter Terms Of Use URL"
              />
            </Form.Item>
          </Col>
          <Col xs={18} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name={['termsOfUse', 'termsOfUseLabel']}
              label="Terms Of Use Label"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please Enter Label',
                },
              ]}
            >
              <InputComponent
                disabled={fieldLevelDisabled({
                  isSponsorRecord: initialValue?.sponsorName?.length > 0,
                  fieldName: 'termsOfUse',
                  editableFields,
                })}
                placeholder="Enter Terms Of Use Label"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h5>Privacy </h5>
          </Col>
          <Col xs={18} sm={18} md={18} lg={18} xl={12} xxl={12}>
            <Form.Item
              name={['privacyPolicy', 'privacyPolicyUrl']}
              label="Privacy Policy Url"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please Enter URL',
                },
                url,
              ]}
            >
              <InputComponent
                disabled={
                  policyUrlDisable?.privacyPolicy ||
                  fieldLevelDisabled({
                    isSponsorRecord: initialValue?.sponsorName?.length > 0,
                    fieldName: 'privacyPolicy',
                    editableFields,
                  })
                }
                placeholder="Enter Privacy Policy URL"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name={['privacyPolicy', 'privacyPolicyLabel']}
              label="Privacy Policy Label"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please Enter Label',
                },
              ]}
            >
              <InputComponent
                disabled={fieldLevelDisabled({
                  isSponsorRecord: initialValue?.sponsorName?.length > 0,
                  fieldName: 'privacyPolicy',
                  editableFields,
                })}
                placeholder="Enter Privacy Policy Label"
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="setting-content-heading">
        <h5>EXPIRATION</h5>
        <span className="panel-description">
          Set expiration for this questionnaire
        </span>
      </div>
      <Row className="setting-content">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item name="isScheduleEnabled" valuePropName="checked">
            <Checkbox
              disabled={fieldLevelDisabled({
                isSponsorRecord: initialValue?.sponsorName?.length > 0,
                fieldName: 'isScheduleEnabled',
                editableFields,
              })}
              onChange={onCheckChange}
              className="common-checkbox"
            >
              Enabled
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {showDatePicker && (
        <Row gutter={8}>
          <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
            <Form.Item
              name="startDate"
              label="Start Date"
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select Start Date',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise?.resolve();
                    }

                    if (moment(value)?.isBefore(moment(), 'day')) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject(
                        'Start date cannot be less than current date!',
                      );
                    }
                    if (
                      getFieldValue('expiryDate') &&
                      value > getFieldValue('expiryDate')
                    ) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject(
                        'Start date cannot be greater than expiry date!',
                      );
                    }

                    return Promise?.resolve();
                  },
                }),
              ]}
            >
              <DatePickerComponent
                disabled={fieldLevelDisabled({
                  isSponsorRecord: initialValue?.sponsorName?.length > 0,
                  fieldName: 'startDate',
                  editableFields,
                })}
                disabledDate
                placeholder="Select Start Date"
                format={dateFormatWithoutTime(globalDateFormat)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
            <Form.Item
              name="expiryDate"
              label="Expiry Date"
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select Expiry Date',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || !getFieldValue('startDate')) {
                      return Promise?.resolve();
                    }

                    if (moment(value)?.isBefore(moment(), 'day')) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject(
                        'Expiry date cannot be less than current date!',
                      );
                    }
                    if (
                      getFieldValue('startDate') &&
                      value < getFieldValue('startDate')
                    ) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject(
                        'Expiry date cannot be less than start date!',
                      );
                    }
                    return Promise?.resolve();
                  },
                }),
              ]}
            >
              <DatePickerComponent
                disabled={fieldLevelDisabled({
                  isSponsorRecord: initialValue?.sponsorName?.length > 0,
                  fieldName: 'expiryDate',
                  editableFields,
                })}
                disabledDate
                placeholder="Select Expiry Date"
                format={dateFormatWithoutTime(globalDateFormat)}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Divider />
    </Card>
  );
};

export default GeneralForm;
