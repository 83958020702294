import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Col, Divider, Empty, Form, Row } from 'antd';
import { debounce, forEach, map, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import SaveIcon from '../../../assets/save.svg';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import {
  checkPermissions,
  dateFormatWithoutTime,
  formValidatorRules,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DatePickerComponent from '../../../components/DatePickerComponent';
import EditorComponent from '../../../components/EditorComponent';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import PriceComponent from '../../../components/PriceComponent';
import SelectComponent from '../../../components/SelectComponent';
import {
  BRAND,
  GET_INDUSTRIES,
  LINE_OF_BUSINESS,
  REGION,
  SUB_AREA,
} from '../graphql/Queries';

let industryScrollDebounceJob;
let brandScrollDebounceJob;
let regionScrollDebounceJob;
let lineOfBusinessScrollDebounceJob;
let subAreaScrollDebounceJob;

const {
  required,
  requiredWhiteSpaceAllowed,
  startDate,
  endDate,
} = formValidatorRules;

const industryFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true,
};
const brandFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  defaultBrand: true,
};
const regionFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
};
const lineOfBusinessFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true,
};
const subAreaFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true,
};

const { Option } = SelectComponent;

export function sortRegionsByName(regions = []) {
  return regions
    .map((item) => ({ ...item }))
    .sort((a, b) => {
      if (a?.name < b?.name) {
        return -1;
      }
      if (a?.name > b?.name) {
        return 1;
      }
      return 0;
    });
}

const ProductForm = (props) => {
  const { productData, handleSubmit, isSubmit, isEdit = false } = props;
  const {
    state: { globalDateFormat, permissions, isSponsor },
    dispatch,
  } = useContext(AppContext);

  const navigate = useNavigate();
  const { state } = useLocation();

  const [validationTriggered, setValidationTriggered] = useState(false);
  const [initialSubAreaRender, setInitialSubAreaRender] = useState(true);
  const [initialLobRender, setInitialLobRender] = useState(true);

  const [industryLoading, setIndustryLoading] = useState(false);
  const [industrySearchFlag, setIndustrySearchFlag] = useState(false);
  const [industriesData, setIndustriesData] = useState([]);
  const [industryIsEnd, setIndustryIsEnd] = useState(false);
  const [industryDebounceCall, setIndustryDebounceCall] = useState(0);

  const [brandLoading, setBrandLoading] = useState(false);
  const [brandSearchFlag, setBrandSearchFlag] = useState(false);
  const [brandsData, setBrandsData] = useState([]);

  const [isEnd, setIsEnd] = useState(false);
  const [debounceCall, setDebounceCall] = useState(0);

  const [regionLoading, setRegionLoading] = useState(false);
  const [regionSearchFlag, setRegionSearchFlag] = useState(false);
  const [regionsData, setRegionsData] = useState([]);
  const [regionIsEnd, setRegionIsEnd] = useState(false);
  const [regionDebounceCall, setRegionDebounceCall] = useState(0);

  const [lineOfBusinessLoading, setLineOfBusinessLoading] = useState(false);
  const [lineOfBusinessSearchFlag, setLineOfBusinessSearchFlag] = useState(
    false,
  );
  const [lineOfBusinessesData, setLineOfBusinessesData] = useState([]);
  const [lineOfBusinessIsEnd, setLineOfBusinessIsEnd] = useState(false);
  const [lineOfBusinessDebounceCall, setLineOfBusinessDebounceCall] = useState(
    0,
  );

  const [subAreaLoading, setSubAreaLoading] = useState(false);
  const [subAreaSearchFlag, setSubAreaSearchFlag] = useState(false);
  const [subAreasData, setSubAreasData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [subAreaIsEnd, setSubAreaIsEnd] = useState(false);
  const [subAreaDebounceCall, setSubAreaDebounceCall] = useState(0);
  const [disableLob, setDisableLob] = useState(true);
  const [disableSubArea, setDisableSubArea] = useState(true);

  const [startDateForm, setStartDate] = useState(null);
  const [endDateForm, setEndDate] = useState(null);

  const [form] = Form?.useForm();

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const [brands] = useLazyQuery(BRAND, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIsEnd(res?.brands?.data?.length < SKIP_RECORD);
      if (brandSearchFlag) {
        setBrandsData([...res?.brands?.data]);
      } else {
        if (isEdit) {
          const tempBrands = [
            ...productData?.productBrands,
            ...res?.brands?.data,
          ];
          setBrandsData(uniqBy([...brandsData, ...tempBrands], 'id'));
          return;
        }
        setBrandsData(uniqBy([...brandsData, ...res?.brands?.data], 'id'));
      }
      setBrandLoading(false);
    },
    onError() {
      setBrandLoading(false);
    },
  });

  const onBrandScroll = (event) => {
    setBrandSearchFlag(false);
    if (brandScrollDebounceJob) {
      brandScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    brandScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setBrandLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        brands({
          variables: {
            filter: {
              ...brandFilter,
              skip: (debounceCall + 1) * SKIP_RECORD,
              search: searchValue,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);

    brandScrollDebounceJob();
  };

  const handleBrandChange = (value) => {
    setBrandSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setBrandLoading(true);
      brands({
        variables: {
          filter: {
            ...brandFilter,
            search: value,
          },
          where: { isActive: true },
        },
      });
    } else {
      setBrandLoading(true);
      brands({
        variables: {
          filter: {
            ...brandFilter,
            search: value,
          },
          where: { isActive: true },
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedBrandHandler = useCallback(
    debounce(handleBrandChange, 500),
    [],
  );

  const handleBrandClear = () => {
    form?.setFieldsValue({
      brands: [],
    });
    setBrandsData([]);
    brands({ variables: { filter: brandFilter, where: { isActive: true } } });
  };

  const handleBrandBlur = () => {
    setSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
    setBrandSearchFlag(true);
    brands({
      variables: {
        filter: brandFilter,
        where: { isActive: true },
      },
    });
  };

  const [regions] = useLazyQuery(REGION, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const sortedRegions = sortRegionsByName(productData?.productRegions);
      setRegionIsEnd(res?.regions?.data?.length < SKIP_RECORD);

      if (regionSearchFlag) {
        setRegionsData([...res?.regions?.data]);
      } else {
        if (isEdit) {
          const tempRegions = uniqBy(
            [...sortedRegions, ...res?.regions?.data],
            'id',
          );
          const regionsCopy = [];
          forEach(sortedRegions, (item) => {
            regionsCopy?.push(item);
            if (item?.subRegions?.length > 0) {
              forEach(item?.subRegions, (data) =>
                regionsCopy?.push({ ...data, parentId: item?.id }),
              );
            }
          });
          setRegionsData((prev) =>
            uniqBy([...regionsCopy, ...prev, ...tempRegions], 'id'),
          );
          return;
        }
        setRegionsData(uniqBy([...regionsData, ...res?.regions?.data], 'id'));
      }
      setRegionLoading(false);
    },
    onError() {
      setRegionLoading(false);
    },
  });

  const onRegionScroll = (event) => {
    setRegionSearchFlag(false);
    if (regionScrollDebounceJob) {
      regionScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    regionScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !regionIsEnd) {
        setRegionLoading(true);
        setRegionDebounceCall((prevState) => prevState + 1);
        regions({
          variables: {
            filter: {
              ...regionFilter,
              skip: (regionDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);

    regionScrollDebounceJob();
  };

  const handleRegionChange = (value) => {
    setRegionSearchFlag(true);
    setSearchValue(value);
    setRegionLoading(true);
    regions({
      variables: {
        filter: {
          ...regionFilter,
          search: value,
        },
        where: { isActive: true },
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRegionHandler = useCallback(
    debounce(handleRegionChange, 500),
    [],
  );

  const handleRegionClear = () => {
    form?.setFieldsValue({
      regions: [],
    });
    setRegionsData([]);
    regions({ variables: { filter: regionFilter, where: { isActive: true } } });
  };

  const handleRegionBlur = () => {
    setSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
    setRegionSearchFlag(false);
    regions({
      variables: {
        filter: regionFilter,
        where: { isActive: true },
      },
    });
  };

  const [industries] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIndustryIsEnd(res?.industries?.data?.length < SKIP_RECORD);
      if (industrySearchFlag) {
        setIndustriesData([...res?.industries?.data]);
      } else {
        if (isEdit) {
          const tempIndustry = [
            productData?.productIndustry,
            ...res?.industries?.data,
          ];
          setIndustriesData(uniqBy([...industriesData, ...tempIndustry], 'id'));
          return;
        }
        setIndustriesData([...industriesData, ...res?.industries?.data]);
      }
      setIndustryLoading(false);
    },
    onError() {
      setIndustryLoading(false);
    },
  });

  const onIndustryScroll = (event) => {
    setIndustrySearchFlag(false);
    if (industryScrollDebounceJob) {
      industryScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !industryIsEnd) {
        setIndustryLoading(true);
        setIndustryDebounceCall((prevState) => prevState + 1);
        industries({
          variables: {
            filter: {
              ...industryFilter,
              skip: (industryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
            },
            where: {
              isActive: true,
            },
          },
        });
      }
    }, 500);

    industryScrollDebounceJob();
  };

  const handleIndustryChange = (value) => {
    setIndustrySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setIndustryLoading(true);
      industries({
        variables: {
          filter: {
            ...industryFilter,
            search: value,
          },
          where: {
            isActive: true,
          },
        },
      });
    } else {
      setIndustryLoading(true);
      industries({
        variables: {
          filter: {
            ...industryFilter,
            search: value,
          },
          where: {
            isActive: true,
          },
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedIndustryHandler = useCallback(
    debounce(handleIndustryChange, 500),
    [],
  );

  const handleIndustryClear = () => {
    form?.setFieldsValue({
      industryId: null,
      lineOfBusinessId: null,
      subAreaId: null,
    });
    setDisableLob(true);
    setDisableSubArea(true);
    setSubAreaSearchFlag(true);
    setIndustriesData([]);
    setLineOfBusinessesData([]);
    setSubAreasData([]);
    industries({
      variables: {
        filter: industryFilter,
        where: {
          isActive: true,
        },
      },
    });
  };

  const handleIndustryBlur = () => {
    setSearchValue('');
    setIndustryDebounceCall(0);
    setIndustryIsEnd(false);
  };
  const [lineOfBusinesses] = useLazyQuery(LINE_OF_BUSINESS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setLineOfBusinessIsEnd(res?.lineOfBusinesses?.data?.length < SKIP_RECORD);
      if (lineOfBusinessSearchFlag) {
        setLineOfBusinessesData([...res?.lineOfBusinesses?.data]);
      } else {
        if (isEdit) {
          if (initialLobRender) {
            const tempLineOfBusiness = [
              productData?.lineOfBusiness,
              ...res?.lineOfBusinesses?.data,
            ];
            setLineOfBusinessesData(
              uniqBy([...lineOfBusinessesData, ...tempLineOfBusiness], 'id'),
            );
          } else {
            setLineOfBusinessesData([...res?.lineOfBusinesses?.data]);
          }
          setInitialLobRender(false);
          return;
        }
        setLineOfBusinessesData([
          ...lineOfBusinessesData,
          ...res?.lineOfBusinesses?.data,
        ]);
      }
      setLineOfBusinessLoading(false);
    },
    onError() {
      setLineOfBusinessLoading(false);
    },
  });

  const onLineOfBusinessScroll = (event) => {
    setLineOfBusinessSearchFlag(false);
    if (lineOfBusinessScrollDebounceJob) {
      lineOfBusinessScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    lineOfBusinessScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !lineOfBusinessIsEnd) {
        setLineOfBusinessLoading(true);
        setLineOfBusinessDebounceCall((prevState) => prevState + 1);
        lineOfBusinesses({
          variables: {
            filter: {
              ...lineOfBusinessFilter,
              skip: (lineOfBusinessDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
              industryId: form?.getFieldValue('industryId'),
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);

    lineOfBusinessScrollDebounceJob();
  };

  const handleLineOfBusinessChange = (value) => {
    setLineOfBusinessSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setLineOfBusinessLoading(true);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            industryId: form?.getFieldValue('industryId'),
            search: value,
          },
        },
      });
    } else {
      setLineOfBusinessLoading(true);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            industryId: form?.getFieldValue('industryId'),
            search: value,
          },
          where: { isActive: true },
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLineOfBusinessHandler = useCallback(
    debounce(handleLineOfBusinessChange, 500),
    [],
  );

  const handleLineOfBusinessClear = () => {
    form?.setFieldsValue({
      lineOfBusinessId: null,
      subAreaId: null,
    });
    setDisableSubArea(true);
    setLineOfBusinessesData([]);
    setSubAreasData([]);
    lineOfBusinesses({
      variables: {
        filter: {
          ...lineOfBusinessFilter,
          industryId: form?.getFieldValue('industryId'),
        },
        where: { isActive: true },
      },
    });
  };

  const handleLineOfBusinessBlur = () => {
    setSearchValue('');
    setLineOfBusinessDebounceCall(0);
    setLineOfBusinessIsEnd(false);
  };

  const [subAreas] = useLazyQuery(SUB_AREA, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setSubAreaIsEnd(res?.subAreas?.data?.length < SKIP_RECORD);
      if (subAreaSearchFlag) {
        setSubAreasData([...res?.subAreas?.data]);
      } else {
        if (isEdit) {
          if (initialSubAreaRender) {
            const tempSubAreas = [productData?.subArea, ...res?.subAreas?.data];
            setSubAreasData(uniqBy([...subAreasData, ...tempSubAreas], 'id'));
          } else {
            setSubAreasData([...res?.subAreas?.data]);
          }
          setInitialSubAreaRender(false);
          return;
        }
        setSubAreasData(
          uniqBy([...subAreasData, ...res?.subAreas?.data], 'id'),
        );
      }
      setSubAreaLoading(false);
    },
    onError() {
      setSubAreaLoading(false);
    },
  });

  const handleLobSelect = (value) => {
    setDisableSubArea(false);
    setSubAreasData([]);
    if (form?.getFieldValue('subAreaId')) {
      form?.setFieldsValue({
        subAreaId: null,
      });
    }
    subAreas({
      variables: {
        filter: {
          ...subAreaFilter,
          lobId: value,
          industryId: form?.getFieldValue('industryId'),
        },
        where: {
          isActive: true,
        },
      },
    });
  };

  const handleIndustrySelect = (value) => {
    setDisableLob(false);
    setLineOfBusinessesData([]);
    setDisableSubArea(true);
    if (form?.getFieldValue('lineOfBusinessId')) {
      form?.setFieldsValue({
        lineOfBusinessId: null,
      });
    }
    if (form?.getFieldValue('subAreaId')) {
      form?.setFieldsValue({
        subAreaId: null,
      });
    }
    lineOfBusinesses({
      variables: {
        filter: { ...lineOfBusinessFilter, industryId: value },
        where: {
          isActive: true,
        },
      },
    });
  };

  const onSubAreaScroll = (event) => {
    setSubAreaSearchFlag(false);
    if (subAreaScrollDebounceJob) {
      subAreaScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    subAreaScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !subAreaIsEnd) {
        setSubAreaLoading(true);
        setSubAreaDebounceCall((prevState) => prevState + 1);
        subAreas({
          variables: {
            filter: {
              ...subAreaFilter,
              skip: (subAreaDebounceCall + 1) * SKIP_RECORD,
              lobId: form?.getFieldValue('lineOfBusinessId'),
              industryId: form?.getFieldValue('industryId'),
              search: searchValue,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);

    subAreaScrollDebounceJob();
  };

  const handleSubAreaChange = (value) => {
    setSubAreaSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setSubAreaLoading(true);
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            search: value,
            lobId: form?.getFieldValue('lineOfBusinessId'),
            industryId: form?.getFieldValue('industryId'),
          },
          where: { isActive: true },
        },
      });
    } else {
      setSubAreaLoading(true);
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            search: value,
            lobId: form?.getFieldValue('lineOfBusinessId'),
            industryId: form?.getFieldValue('industryId'),
          },
          where: { isActive: true },
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubAreaHandler = useCallback(
    debounce(handleSubAreaChange, 500),
    [],
  );

  const handleSubAreaClear = () => {
    form?.setFieldsValue({
      subAreaId: null,
    });
    setSubAreasData([]);
    subAreas({
      variables: {
        filter: {
          ...subAreaFilter,
          lobId: form?.getFieldValue('lineOfBusinessId'),
          industryId: form?.getFieldValue('industryId'),
        },
        where: { isActive: true },
      },
    });
  };

  const handleSubAreaBlur = () => {
    setSearchValue('');
    setSubAreaDebounceCall(0);
    setSubAreaIsEnd(false);
  };

  useEffect(() => {
    industries({
      variables: {
        filter: industryFilter,
        where: {
          isActive: true,
        },
      },
    });
    brands({
      variables: {
        filter: brandFilter,
        where: { isActive: true },
      },
    });
    regions({
      variables: {
        filter: regionFilter,
        where: { isActive: true },
      },
    });
    if (productData?.industryId) {
      setDisableLob(false);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            industryId: productData?.industryId,
          },
          where: {
            isActive: true,
          },
        },
      });
    }
    if (productData?.lineOfBusinessId) {
      setDisableSubArea(false);
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            industryId: productData?.industryId,
            lobId: productData?.lineOfBusinessId,
          },
          where: { isActive: true },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeStartDate = (value) => {
    if (!value) {
      setStartDate(null);
      return;
    }
    setStartDate(moment(value)?.startOf('day'));
  };

  const handleChangeEndDate = (value) => {
    if (!value) {
      setEndDate(null);
      return;
    }
    setEndDate(moment(value)?.endOf('day'));
  };

  return (
    <div>
      <AccessControl
        allowedPermissions={
          isEdit ? ['FET_PRODUCT_UPDATE'] : ['FET_PRODUCT_CREATE']
        }
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="product-table-discard-btn"
            onClick={() => navigate(ROUTES?.PACKAGES, { state: { ...state } })}
          >
            {isEdit ? 'Discard Changes' : 'Cancel'}
          </Button>
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            disabled={!isSponsor && productData?.sponsorName?.length > 0}
            size="small"
            htmlType="submit"
            id="product-table-save-btn"
            loading={isSubmit}
            type="primary"
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={productData}
        disabled={!isSponsor && productData?.sponsorName?.length > 0}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFieldsChange={() => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: true });
        }}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit ? ['FET_PRODUCT_UPDATE'] : ['FET_PRODUCT_CREATE'],
            )
          }
        >
          <span className="form-divider-text">MANDATORY</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter SKU' }]}
                name="sku"
                label="SKU"
              >
                <InputComponent allowClear placeholder="Enter SKU" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Select Package Type' }]}
                name="productType"
                label="Package Type"
              >
                <SelectComponent placeholder="Select Package Type" allowClear>
                  <Option key="single" value="Single">
                    Single
                  </Option>
                  <Option key="bundle" value="Bundle">
                    Bundle
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Name' }]}
                name="title"
                label="Name"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
            </Col>
            {isEdit && (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="price" label="Price">
                  <PriceComponent disabled placeholder="Price" isPrice />
                </Form.Item>
              </Col>
            )}
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="industryId"
                label="Industry"
                rules={[
                  {
                    required,
                    message: 'Please Select Industry',
                  },
                ]}
              >
                <SelectComponent
                  placeholder="Select Industry"
                  allowClear
                  notFoundContent={
                    industryLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleIndustryBlur}
                  onSearch={debouncedIndustryHandler}
                  onClear={handleIndustryClear}
                  onPopupScroll={onIndustryScroll}
                  onSelect={handleIndustrySelect}
                >
                  {map(industriesData, (item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="lineOfBusinessId"
                label="Line Of Business"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Line Of Business',
                  },
                ]}
              >
                <SelectComponent
                  placeholder="Select Line Of Business"
                  allowClear
                  // added disabled field level as form level don't work here
                  disabled={
                    disableLob ||
                    (!isSponsor && productData?.sponsorName?.length > 0)
                  }
                  notFoundContent={
                    lineOfBusinessLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onSelect={handleLobSelect}
                  onBlur={handleLineOfBusinessBlur}
                  onSearch={debouncedLineOfBusinessHandler}
                  onClear={handleLineOfBusinessClear}
                  onPopupScroll={onLineOfBusinessScroll}
                >
                  {map(lineOfBusinessesData, (item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="subAreaId"
                label="Service Type"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Service Type',
                  },
                ]}
              >
                <SelectComponent
                  // added disabled field level as form level don't work here
                  disabled={
                    disableSubArea ||
                    (!isSponsor && productData?.sponsorName?.length > 0)
                  }
                  placeholder="Select Service Type"
                  allowClear
                  notFoundContent={
                    subAreaLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleSubAreaBlur}
                  onSearch={debouncedSubAreaHandler}
                  onClear={handleSubAreaClear}
                  onPopupScroll={onSubAreaScroll}
                >
                  {map(subAreasData, (item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="isActive"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status',
                  },
                ]}
              >
                <SelectComponent placeholder="Select Status" allowClear>
                  <Option key="active" value>
                    Active
                  </Option>
                  <Option key="inactive" value={false}>
                    InActive
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
            {isEdit && (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="sellingPrice" label="Package Price">
                  <PriceComponent
                    disabled
                    placeholder="Enter Package Price"
                    isPrice
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Package Quality',
                  },
                ]}
                name="productQuality"
                label="Package Quality"
              >
                <SelectComponent
                  placeholder="Select Package Quality"
                  allowClear
                >
                  <Option key="GOOD" value="GOOD">
                    Good
                  </Option>
                  <Option key="BETTER" value="BETTER">
                    Better
                  </Option>
                  <Option key="BEST" value="BEST">
                    Best
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="score"
                label="Package Ranking"
                className="label-with-tooltip"
                tooltip={{
                  title:
                    'Enter a value (0-100) in this field, where higher numbers indicate better rankings.',
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Enter Package Ranking',
                  },
                ]}
              >
                <PriceComponent
                  prefix=""
                  decimalValue={2}
                  // added disabled field level as form level don't work here
                  disabled={!isSponsor && productData?.sponsorName?.length > 0}
                  isAllowed={({ value }) => {
                    if (Number(value) < 0 || Number(value) > 100) {
                      return false;
                    }
                    return true;
                  }}
                  allowNegative={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="required-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Form.Item
                rules={[
                  {
                    required,
                    message: 'Please Select Retailers',
                    type: 'array',
                  },
                ]}
                name="brands"
                label="Retailers"
              >
                <SelectComponent
                  placeholder="Select Retailers"
                  allowClear
                  mode="multiple"
                  notFoundContent={
                    brandLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleBrandBlur}
                  onSearch={debouncedBrandHandler}
                  onClear={handleBrandClear}
                  onPopupScroll={onBrandScroll}
                >
                  {map(brandsData, (item) => {
                    if (isSponsor) {
                      return (
                        <Option key={item?.id} value={item?.id}>
                          {item?.name}
                        </Option>
                      );
                    }
                    return (
                      <Option key={item?.id} value={item?.id}>
                        {`${item?.name}  ${
                          item?.sponsorName ? `- ${item?.sponsorName}` : ' '
                        }`}
                      </Option>
                    );
                  })}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Form.Item
                rules={[
                  { required, message: 'Please Select Regions', type: 'array' },
                ]}
                name="regions"
                label="Regions"
              >
                <SelectComponent
                  placeholder="Select Regions"
                  allowClear
                  filterOption={false}
                  mode="multiple"
                  notFoundContent={
                    regionLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleRegionBlur}
                  onSearch={debouncedRegionHandler}
                  onClear={handleRegionClear}
                  onPopupScroll={onRegionScroll}
                  optionLabelProp="children" // we can use title ,too
                >
                  {map(regionsData, (item) => (
                    <Option
                      key={item?.name}
                      value={item?.id}
                      title={item?.name}
                    >
                      <span className={`${item?.parentId ? 'sub-region' : ''}`}>
                        {item?.name}
                      </span>
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          <span className="form-divider-text">OPTIONAL</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            {!isSponsor && productData?.sponsorName?.length > 0 && (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="sponsorName" label="Sponsor">
                  <InputComponent disabled />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="description" label="Package Description">
                <EditorComponent
                  // added disabled as form level not work here
                  disabled={!isSponsor && productData?.sponsorName?.length > 0}
                  placeholder="Package Description Goes here..."
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="startData"
                label="Start Date"
                normalize={(value) => {
                  if (value) {
                    return moment(value)?.startOf('day');
                  }
                }}
                rules={[
                  {
                    ...startDate(endDateForm),
                  },
                ]}
              >
                <DatePickerComponent
                  disabledDate
                  onChange={handleChangeStartDate}
                  placeholder="Select Start Date"
                  format={dateFormatWithoutTime(globalDateFormat)}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="endDate"
                label="Expiry Date"
                normalize={(value) => {
                  if (value) {
                    return moment(value)?.endOf('day');
                  }
                  return null;
                }}
                rules={[
                  {
                    ...endDate(startDateForm),
                  },
                ]}
              >
                <DatePickerComponent
                  disabledDate
                  onChange={handleChangeEndDate}
                  placeholder="Select Expiry Date"
                  format={dateFormatWithoutTime(globalDateFormat)}
                />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default ProductForm;
