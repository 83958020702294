import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Popconfirm,
  Popover,
  Tag,
} from 'antd';
import {
  debounce,
  filter,
  forEach,
  isEmpty,
  map,
  startCase,
  unionBy,
} from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { messageContext } from '../../../app/components/AppContextHolder';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import ShowMoreIconComponent from '../../../app/components/iconComponents/ShowMoreIconComponent';
import { AppContext } from '../../../AppContext';
import FilterIcon from '../../../assets/filter.svg';
import {
  CONDITIONS,
  CONDITIONS_KEYS,
  ENTITY_FIELDS_DATA_KEYS,
  ENTITY_FIELDS_WRAPPER,
  NUMERIC_CONDITIONS,
  ROUTES,
  SKIP_RECORD,
} from '../../../common/constants';
import {
  checkPermissions,
  getEntityFieldName,
  objectWithoutKey,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import { DELETE_BATCH_ENTITY } from '../graphql/Mutations';
import {
  GET_BATCH_ENTITIES,
  GET_BATCH_ENTITY_FILTER,
} from '../graphql/Queries';
import CreateEntityBatchModal from './EntityBatchModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
};

const MAX_BATCH_ENTITIES = 10;

let scrollDebounce;

export const createEntityFilterMessage = (
  entityFilter,
  globalDateFormat = 'MM-DD-YY',
) => {
  const allConditions = unionBy(NUMERIC_CONDITIONS, CONDITIONS, 'value');

  const conditionValue = allConditions?.find(
    (condObj) => condObj?.value === entityFilter?.key,
  );

  let message = null;

  if (
    entityFilter?.key === CONDITIONS_KEYS?.EQUAL ||
    entityFilter?.key === CONDITIONS_KEYS?.NOT_EQUAL ||
    entityFilter?.key === CONDITIONS_KEYS?.EQUAL_TO ||
    entityFilter?.key === CONDITIONS_KEYS?.NOT_EQUAL_TO
  ) {
    if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.DATE_TIME) {
      message = (
        <div>
          <Tag className="single-item">{conditionValue?.label} </Tag>
          {moment(entityFilter?.values)?.format(globalDateFormat)}
        </div>
      );
    } else if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.PICK_LIST) {
      const labels = entityFilter?.values?.map(
        (item) => item?.name || item?.title || item?.label,
      );
      const slicedLabels = labels?.slice(0, 5);
      message = (
        <div>
          <Tag className="single-item">{conditionValue?.label}</Tag>
          {slicedLabels?.join(', ')}
          {labels?.length > 5 && (
            <Popover
              overlayClassName="condition-popover"
              placement="rightBottom"
              content={<span>{labels?.join(', ')}</span>}
            >
              <Tag className="single-item pointer more-shadow ml-5">
                <span className="content-text">
                  {labels?.length - 1 > 0
                    ? `${labels?.length - 1} more`
                    : 'show more'}
                  <ShowMoreIconComponent className="show-more-icon" />
                </span>
              </Tag>
            </Popover>
          )}
        </div>
      );
    } else if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.BOOLEAN) {
      message = (
        <span>
          <Tag className="single-item">{conditionValue?.label}</Tag>{' '}
          {entityFilter?.values?.toString()}
        </span>
      );
    } else {
      message = (
        <span>
          <Tag className="single-item">{conditionValue?.label}</Tag>{' '}
          {entityFilter?.values}
        </span>
      );
    }
  }
  if (
    entityFilter?.key === CONDITIONS_KEYS?.CONTAINS ||
    entityFilter?.key === CONDITIONS_KEYS?.NOT_CONTAINS
  ) {
    if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.DATE_TIME) {
      message = (
        <span>
          <Tag className="single-item">{conditionValue?.label} $</Tag>
          {moment(entityFilter?.values).format(globalDateFormat)}
        </span>
      );
    } else if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.PICK_LIST) {
      const labels = entityFilter?.values?.map(
        (item) => item?.label || item?.name || item?.title,
      );
      message = (
        <span>
          <Tag className="single-item">{conditionValue?.label}</Tag>
          {labels?.join(', ')}
        </span>
      );
    } else {
      message = (
        <span>
          <Tag className="single-item">{conditionValue?.label}</Tag>
          {entityFilter?.values?.toString()}
        </span>
      );
    }
  }
  if (conditionValue?.value === CONDITIONS_KEYS?.GREATER_LESS_RANGE) {
    // need to check for data types
    let convertedGreaterThanValue;
    let convertedLessThanValue;
    if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.DATE_TIME) {
      convertedGreaterThanValue = moment(
        entityFilter?.values?.greaterThan?.max,
      )?.format(globalDateFormat);
      convertedLessThanValue = moment(
        entityFilter?.values?.lessThan?.min,
      )?.format(globalDateFormat);
    } else {
      convertedGreaterThanValue = entityFilter?.values?.greaterThan?.max?.toLocaleString(
        'en',
        { useGrouping: false, minimumFractionDigits: 2 },
      );
      convertedLessThanValue = entityFilter?.values?.lessThan?.min?.toLocaleString(
        'en',
        {
          useGrouping: false,
          minimumFractionDigits: 2,
        },
      );
    }

    if (
      entityFilter?.values?.greaterThan?.check &&
      entityFilter?.values?.lessThan?.check
    ) {
      message = (
        <div>
          <Tag className="single-item">in Between</Tag>{' '}
          {convertedGreaterThanValue} - {convertedLessThanValue}
        </div>
      );
    }

    if (
      entityFilter?.values?.greaterThan?.check &&
      !entityFilter?.values?.lessThan?.check
    ) {
      message = (
        <div>
          <Tag className="single-item">is Greater Than</Tag>{' '}
          {convertedGreaterThanValue}
        </div>
      );
    }
    if (
      !entityFilter?.values?.greaterThan?.check &&
      entityFilter?.values?.lessThan?.check
    ) {
      message = (
        <div>
          <Tag className="single-item">is Less Than</Tag>{' '}
          {convertedLessThanValue}
        </div>
      );
    }
  }
  return message;
};

const conditionRenderer = (conditions) => {
  const totalConditions = conditions?.length;
  const firstCondition = conditions?.[0];
  const entityName = Object?.keys(ENTITY_FIELDS_WRAPPER)?.find(
    (entity) => entity === firstCondition?.field,
  );
  const content = (
    <div className="conditions">
      {map(conditions, (conditionObj) => {
        const entityNameInPopover = Object?.keys(ENTITY_FIELDS_WRAPPER)?.find(
          (entity) => entity === conditionObj?.field,
        );
        return (
          <>
            <span className="condition-title">
              {entityNameInPopover
                ? ENTITY_FIELDS_WRAPPER?.[entityNameInPopover]
                : startCase(conditionObj?.field)}{' '}
              :
            </span>
            <span className="condition-data">
              {createEntityFilterMessage(conditionObj)}
            </span>
          </>
        );
      })?.reduce((prev, curr) => {
        if (prev === '') {
          return curr;
        }
        return [prev, ', ', curr];
      }, '')}
    </div>
  );
  return (
    <div className="d-flex align-center">
      <span className="condition-title">
        {entityName
          ? ENTITY_FIELDS_WRAPPER?.[entityName]
          : startCase(firstCondition?.field)}{' '}
        : &nbsp;
      </span>
      <span className="condition-data">
        {createEntityFilterMessage(firstCondition)}
      </span>
      {conditions?.length > 1 && (
        <Popover
          overlayClassName="condition-popover"
          placement="right"
          content={content}
        >
          <Tag className="single-item pointer more-shadow ml-5">
            <span className="content-text">
              {totalConditions - 1 > 0
                ? `${totalConditions - 1} more`
                : 'show more'}
              <ShowMoreIconComponent className="show-more-icon" />
            </span>
          </Tag>
        </Popover>
      )}
    </div>
  );
};
const BatchEntityTable = ({ lobId = null }) => {
  const {
    state: { pageSize, permissions },
  } = useContext(AppContext);

  const initialBatchEntityFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
      sortOn: 'entityName',
      sortBy: 'DESC',
    }),
    [pageSize],
  );

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [batchEntitiesFilter, setBatchEntitiesFilter] = useState(
    initialBatchEntityFilter,
  );
  const [filters, setFilters] = useState(null);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [batchEntityData, setBatchEntityData] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(null);

  const [initialValues, setInitialValues] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { id: batchId } = useParams();

  const [openEntityBatchModal, setOpenEntityBatchModal] = useState(false);

  const [fetchBatchEntitiesData, { loading, data }] = useLazyQuery(
    GET_BATCH_ENTITIES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.batchEntities?.count,
        };

        const batchEntitiesRecords = res?.batchEntities?.data?.map((record) => {
          if (record?.children?.length > 0) {
            const updatedChildren = record?.children?.map((item, index) => ({
              ...item,
              parentId: record?.id,
              // we have to use index to make unique id for children's as they have only one common id i.e parentId
              // childId is used to handle open state for popover in action column
              childId: `${record?.id}-${index}`,
            }));
            return {
              ...record,
              children: [...updatedChildren],
            };
          }
          const recordWithoutChildren = objectWithoutKey(record, 'children');
          return {
            ...recordWithoutChildren,
          };
        });
        setBatchEntityData([...batchEntitiesRecords]);
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );

  const [fetchBatchesEntitiesFilter] = useLazyQuery(GET_BATCH_ENTITY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.batchEntityFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.batchEntityFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.batchEntityFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError() {},
  });

  const [deleteBatchEntity, { loading: deleteBatchLoading }] = useMutation(
    DELETE_BATCH_ENTITY,
    {
      onError() {},
    },
  );

  const getFilterData = (confirm) => {
    fetchBatchEntitiesData({
      variables: {
        filter: { ...batchEntitiesFilter, skip: 0 },
        where: {
          batchId,
          ...(filtersCopyState && { ...filtersCopyState }),
        },
      },
    });
    setFilters(filtersCopyState);
    setBatchEntitiesFilter({
      ...batchEntitiesFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);

      fetchBatchesEntitiesFilter({
        variables: {
          filter: {
            sortOn: filterIndex,
            sortBy: 'ASC',
            skip: 0,
            limit: 20,
            search: filterSearch,
            distinct: true,
            getDBField: filterIndex,
          },
        },
      });
    }
    if (!filterVisible) {
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  useEffect(() => {
    const permitted = checkPermissions(permissions, [
      'FET_DATA_ASSIGNMENT_LIST',
    ]);
    const whereFilter = location?.state;
    let parsedObject;

    if (!isEmpty(whereFilter)) {
      parsedObject = JSON?.parse(whereFilter?.stringifyObject);
    }
    if (permitted) {
      fetchBatchEntitiesData({
        variables: {
          filter: parsedObject?.batchEntitiesFilter ?? batchEntitiesFilter,
          where: {
            batchId,
            ...(parsedObject?.filters && { ...parsedObject?.filters }),
          },
        },
      });

      if (!isEmpty(parsedObject?.batchEntitiesFilter)) {
        setFilters({ ...parsedObject?.filters });
        setFiltersCopyState({
          ...parsedObject?.filters,
        });
        const sorter = {
          order:
            parsedObject?.batchEntitiesFilter?.sortBy === 'ASC'
              ? 'ascend'
              : 'descend',
          columnKey: parsedObject?.batchEntitiesFilter?.sortOn,
        };
        setSortedInfo({ ...sorter });
        setBatchEntitiesFilter({ ...parsedObject?.batchEntitiesFilter });
        setPaginationProp({ ...parsedObject?.paginationProp });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('beforeunload', () => {
      // eslint-disable-next-line no-undef
      if (window?.location?.pathname === ROUTES?.ENTITY_FILTERS)
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
    });
    return () => {
      // eslint-disable-next-line no-undef
      window?.removeEventListener('beforeunload', () => {
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
      });
    };
  }, []);

  const refetchEntityFieldsData = () => {
    fetchBatchEntitiesData({
      variables: {
        filter: batchEntitiesFilter,
        where: {
          batchId,
          ...(filters && { filters }),
        },
      },
    });
  };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        fetchBatchesEntitiesFilter({
          variables: {
            filter: {
              sortOn: filterIndex,
              sortBy: 'ASC',
              skip: filterList?.length,
              limit: 20,
              search: filterSearch,
              distinct: true,
              getDBField: filterIndex,
            },
          },
        });
      }
    }, 500);
    scrollDebounce();
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];

    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleReset = (clearFilters, dataIndex) => {
    let filtersCopy = {
      ...filters,
    };
    filtersCopy = {
      ...filters,
      [dataIndex]: [],
    };

    setFilters(filtersCopy);
    setFiltersCopyState(filtersCopy);

    fetchBatchEntitiesData({
      variables: {
        filter: {
          ...batchEntitiesFilter,
          skip: 0,
          sortOn: 'entityName',
          sortBy: 'DESC',
        },
        where: {
          batchId,
          ...(filtersCopy && { ...filtersCopy }),
        },
      },
    });

    setBatchEntitiesFilter({
      ...batchEntitiesFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    fetchBatchesEntitiesFilter({
      variables: {
        filter: {
          sortOn: dataIndex,
          sortBy: 'ASC',
          skip: 0,
          limit: 20,
          search: value,
          distinct: true,
          getDBField: dataIndex,
        },
      },
    });
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'status' && (
            <SearchComponent
              className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => (
                <Tag
                  key={item?.toString()}
                  closable
                  onClose={() => handleDeleteFilter(item, dataIndex)}
                  className="filter-tag"
                >
                  {dataIndex === 'isActive' ? (
                    <span title={item === true ? 'Active' : 'Inactive'}>
                      {item === true ? 'Active' : 'Inactive'}
                    </span>
                  ) : (
                    <span title={item?.label || item?.toString()}>
                      {getEntityFieldName(item?.label || item?.toString())}
                    </span>
                  )}
                </Tag>
              ))}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => (
                <div
                  className="filter-checkbox-section"
                  key={item?.key || item?.label || item}
                >
                  <Checkbox
                    value={item?.key || item?.label || item}
                    checked={filtersCopyState?.[dataIndex]?.includes(
                      item?.key || item?.label || item,
                    )}
                    key={item?.key || item?.label || item}
                    onChange={(e) => changeFilter(e, dataIndex)}
                    className="common-checkbox"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span title={item?.label || item?.toString()}>
                        {getEntityFieldName(item?.label || item?.toString())}
                      </span>
                    )}
                  </Checkbox>
                </div>
              ))
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const handleDeleteBatchEntity = async (editBatchEntityData) => {
    const response = await deleteBatchEntity({
      variables: { where: { id: editBatchEntityData?.id } },
    });

    if (response?.data?.deleteBatchEntity) {
      fetchBatchEntitiesData({
        variables: {
          filter: batchEntitiesFilter,
          where: {
            batchId,
            ...(filters && { filters }),
          },
        },
      });
    }
  };

  const handleEditBatchEntityData = (editBatchEntityData) => {
    setIsEdit(true);
    setInitialValues(editBatchEntityData);
    setPopoverOpen(null);
    setOpenEntityBatchModal(true);
  };

  const renderActionButtons = (editBatchEntityData) => (
    <div className="d-flex flex-vertical">
      {editBatchEntityData?.conditions?.length > 0 && (
        <>
          <AccessControl
            allowedPermissions={[
              'FET_DATA_ASSIGNMENT_UPDATE',
              'FET_DATA_ASSIGNMENT_VIEW',
            ]}
          >
            <Button
              id="product-table-edit-btn"
              className="b-0"
              onClick={() => handleEditBatchEntityData(editBatchEntityData)}
            >
              {editBatchEntityData?.pickListBatchStatus?.key === 'UNSCHEDULED'
                ? 'Edit'
                : 'View'}
            </Button>
          </AccessControl>
          {editBatchEntityData?.pickListBatchStatus?.key === 'UNSCHEDULED' && (
            <AccessControl allowedPermissions={['FET_DATA_ASSIGNMENT_DELETE']}>
              <Popconfirm
                title="Are you sure to delete?"
                onConfirm={() => handleDeleteBatchEntity(editBatchEntityData)}
                okText="Yes"
                cancelText="No"
              >
                <Button id="batch-table-status-btn" className="b-0">
                  Remove
                </Button>
              </Popconfirm>
            </AccessControl>
          )}
        </>
      )}
      <AccessControl
        allowedPermissions={[
          'FET_DATA_ASSIGNMENT_UPDATE',
          'FET_DATA_ASSIGNMENT_VIEW',
        ]}
      >
        <Button
          id="product-table-edit-btn"
          className="b-0"
          onClick={() => {
            const entityName = editBatchEntityData?.entityName;
            const id = editBatchEntityData?.id || editBatchEntityData?.parentId;
            navigate(
              `${ROUTES?.ASSIGNMENT_JOBS}/edit/${batchId}${ROUTES?.ENTITY_FILTERS}/${id}`,
              {
                state: {
                  ...location?.state,
                  entityName,
                },
              },
            );
          }}
        >
          View Records
        </Button>
      </AccessControl>
    </div>
  );

  const columns = [
    {
      title: 'ENTITY',
      dataIndex: 'entityName',
      key: 'entityName',
      sorter: true,
      width: 190,
      ellipsis: true,

      sortOrder: sortedInfo?.columnKey === 'entityName' && sortedInfo?.order,
      ...filterPopup('entityName'),
      render: (entityName) => <span>{getEntityFieldName(entityName)}</span>,
    },
    {
      title: 'FILTERS',
      dataIndex: 'conditions',
      key: 'conditions',
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'conditions' && sortedInfo?.order,
      render: (conditions) =>
        conditions?.length > 0 && <div>{conditionRenderer(conditions)}</div>,
    },
    {
      title: 'TOTAL RECORDS',
      dataIndex: 'totalRecords',
      key: 'totalRecords',
      sorter: true,
      align: 'right',
      width: 150,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'totalRecords' && sortedInfo?.order,
    },
    {
      title: 'PROCESS RECORDS',
      dataIndex: 'processedRecords',
      key: 'processedRecords',
      sorter: true,
      align: 'right',
      width: 190,
      ellipsis: true,
      sortOrder:
        sortedInfo?.columnKey === 'processedRecords' && sortedInfo?.order,
    },
    {
      title: 'FAILED RECORDS',
      dataIndex: 'failedRecords',
      key: 'failedRecords',
      sorter: true,
      align: 'right',
      width: 150,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'failedRecords' && sortedInfo?.order,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: 150,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
      render: (status, record = {}) => (
        <span>{record?.pickListStatus?.label}</span>
      ),
    },

    checkPermissions(permissions, [
      'FET_DATA_ASSIGNMENT_UPDATE',
      'FET_DATA_ASSIGNMENT_DELETE',
      'FET_DATA_ASSIGNMENT_VIEW',
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => {
        const recordId = record?.childId ? record?.childId : id;
        return (
          <Popover
            placement="bottomLeft"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
            trigger="hover"
            open={popoverOpen === recordId} // Show Popover only for the current row
            onOpenChange={(visible) => {
              setPopoverOpen(visible ? recordId : null);
            }}
          >
            <MoreOutlined />
          </Popover>
        );
      },
    },
  ];

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setBatchEntitiesFilter({
        ...batchEntitiesFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchBatchEntitiesData({
        variables: {
          filter: {
            ...batchEntitiesFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          where: {
            batchId,
            ...(filters && { filters }),
          },
        },
      });
    } else {
      setBatchEntitiesFilter({
        ...batchEntitiesFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      fetchBatchEntitiesData({
        variables: {
          filter: {
            ...batchEntitiesFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'entityName',
            sortBy: 'DESC',
          },
          where: {
            batchId,
            ...(filters && { filters }),
          },
        },
      });
    }
  };

  return (
    <div>
      {openEntityBatchModal && (
        <CreateEntityBatchModal
          showModal={openEntityBatchModal}
          setShowModal={setOpenEntityBatchModal}
          refetchEntityFieldsData={refetchEntityFieldsData}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          lobId={lobId}
        />
      )}
      {(batchEntityData?.length === 0 ||
        batchEntityData?.[0]?.pickListBatchStatus?.key === 'UNSCHEDULED') && (
        <AccessControl allowedPermissions={['FET_DATA_ASSIGNMENT_CREATE']}>
          <Portal portalId="header-right-content">
            <Button
              className="common-button"
              icon={<PlusOutlined />}
              size="small"
              id="product-table-add-btn"
              type="primary"
              onClick={() => {
                if (data?.batchEntities?.count === MAX_BATCH_ENTITIES) {
                  messageContext.error(
                    `You can only create ${MAX_BATCH_ENTITIES} batch entities`,
                  );
                  return;
                }
                setOpenEntityBatchModal(true);
              }}
            >
              Add Entity
            </Button>
          </Portal>
        </AccessControl>
      )}
      <AccessControl
        allowedPermissions={['FET_DATA_ASSIGNMENT_LIST']}
        showNoAccess
      >
        <div className="common-table batch-entity-table">
          {pageSize && (
            <TableComponent
              loadingData={loading || deleteBatchLoading}
              columns={[...columns?.filter((item) => item !== false)]}
              expandable={{
                indentSize: 0,
              }}
              data={batchEntityData}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default BatchEntityTable;
