import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Empty,
  Popconfirm,
  Popover,
  Row,
  Tag,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { messageContext } from '../../../app/components/AppContextHolder';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import ImportIcon from '../../../assets/import.svg';
import { ROUTES, SKIP_RECORD, exportOptions } from '../../../common/constants';
import {
  checkPermissions,
  formatPrice,
  handleExportCommon,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import PriceComponent from '../../../components/PriceComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import ImportModal from '../../imports/components/ImportModal';
import { DELETE_PRODUCT, UPDATE_PRODUCT } from '../graphql/Mutations';
import {
  GET_PRODUCTS,
  GET_SPONSOR_FILTER,
  INDUSTRY_FILTER,
  LINE_OF_BUSINESS_FILTER,
  PRODUCT_FILTER,
  SUB_AREA_FILTER,
} from '../graphql/Queries';

let scrollDebounce = null;

const initialPaginationValue = {
  total: 0,
  current: 1,
};

const ProductTable = () => {
  const {
    state: { pageSize, filterData, permissions, isSponsor },
  } = useContext(AppContext);

  const initialProductFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
      sortOn: 'createdAt',
      sortBy: 'DESC',
    }),
    [pageSize],
  );

  const qualityObjCopy = {
    GOOD: 'Good',
    BETTER: 'Better',
    BEST: 'Best',
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showImportModal, setShowImportModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [productFilter, setProductFilter] = useState(initialProductFilter);
  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [searchValue, setSearchValue] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [updateProductMutate, { loading: updateProductLoading }] = useMutation(
    UPDATE_PRODUCT,
    {
      onError() {},
    },
  );

  const [deleteProduct, { loading: deleteProductLoading }] = useMutation(
    DELETE_PRODUCT,
    {
      onError() {},
    },
  );

  const [fetchProductData, { loading, data }] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.products?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  const [productFilters] = useLazyQuery(PRODUCT_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.productFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.productFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.productFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [industryFilters] = useLazyQuery(INDUSTRY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.industryFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [lineOfBusinessFilters] = useLazyQuery(LINE_OF_BUSINESS_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.lineOfBusinessFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [subAreaFilters] = useLazyQuery(SUB_AREA_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.subAreaFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [sponsorFilter] = useLazyQuery(GET_SPONSOR_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.sponsorFilters?.data, (item) =>
          optionsCopy?.push({
            label: item?.businessName,
            key: item?.businessName,
          }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.sponsorFilters?.data, (item) =>
          optionsCopy?.push({
            label: item?.businessName,
            key: item?.businessName,
          }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.sponsorFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, ['FET_PRODUCT_LIST']);
    const whereFilter = location?.state;
    let parsedObject;

    if (!isEmpty(whereFilter)) {
      parsedObject = JSON?.parse(whereFilter?.stringifyObject);
    }

    if (permitted) {
      fetchProductData({
        variables: {
          filter: parsedObject?.productFilter ?? productFilter,
          ...(parsedObject && { where: parsedObject?.filters }),
        },
      });
      if (!isEmpty(parsedObject)) {
        setFilters({ ...parsedObject?.filters });
        setFiltersCopyState({
          ...parsedObject?.filters,
        });
        setSearchValue(parsedObject?.productFilter?.search);
        const sorter = {
          order:
            parsedObject?.productFilter?.sortBy === 'ASC'
              ? 'ascend'
              : 'descend',
          columnKey: parsedObject?.productFilter?.sortOn,
        };
        setSortedInfo({ ...sorter });
        setProductFilter({ ...parsedObject?.productFilter });
        setPaginationProp({ ...parsedObject?.paginationProp });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('beforeunload', () => {
      // eslint-disable-next-line no-undef
      if (window?.location?.pathname === ROUTES?.PACKAGES)
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
    });
    return () => {
      // eslint-disable-next-line no-undef
      window?.removeEventListener('beforeunload', () => {
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
      });
    };
  }, []);

  const getFilterData = (confirm, dataIndex) => {
    if (dataIndex === 'sellingPrice' || dataIndex === 'price') {
      if (
        !filtersCopyState?.[dataIndex]?.min?.toString() ||
        !filtersCopyState?.[dataIndex]?.max?.toString()
      ) {
        messageContext?.destroy();
        messageContext?.error('Please enter values');
        return;
      }
      if (filtersCopyState?.[dataIndex]?.min < 0) {
        return messageContext?.error('Min value can not be less than zero');
      }
      if (filtersCopyState?.[dataIndex]?.max < 0) {
        return messageContext?.error('Max value can not be less than zero');
      }
      if (
        filtersCopyState?.[dataIndex]?.min >= filtersCopyState?.[dataIndex]?.max
      ) {
        return messageContext?.error('Max value can`t be less than min value');
      }
    }
    fetchProductData({
      variables: {
        filter: { ...productFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState }),
      },
    });
    setFilters(filtersCopyState);
    setProductFilter({
      ...productFilter,
      skip: 0,
    });

    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'industry':
          industryFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                getDBField: 'label',
                justShow: true,
              },
            },
          });
          break;

        case 'lineOfBusiness':
          lineOfBusinessFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                getDBField: 'label',
              },
            },
          });
          break;

        case 'subArea':
          subAreaFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                getDBField: 'label',
              },
            },
          });
          break;

        case 'sponsorName':
          sponsorFilter({
            variables: {
              filter: {
                sortOn: 'businessName',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: 'businessName',
              },
            },
          });
          break;

        case 'sellingPrice':
        case 'price':
          // no api call for range filter;
          break;

        default:
          productFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
              },
            },
          });
          break;
      }
    }
    if (!filterVisible) {
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'industry':
            industryFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  getDBField: 'label',
                  justShow: true,
                },
              },
            });
            break;

          case 'lineOfBusiness':
            lineOfBusinessFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  justShow: true,
                  getDBField: 'label',
                },
              },
            });
            break;

          case 'subArea':
            subAreaFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  justShow: true,
                  getDBField: 'label',
                },
              },
            });
            break;

          case 'sponsorName':
            sponsorFilter({
              variables: {
                filter: {
                  sortOn: 'businessName',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: 'businessName',
                },
              },
            });
            break;

          default:
            productFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                },
              },
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id,
      });
    },
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: [],
    };
    if (dataIndex === 'sellingPrice' || dataIndex === 'price') {
      delete filtersCopy?.[dataIndex];
    }
    setFilters(filtersCopy);
    setFiltersCopyState(filtersCopy);
    fetchProductData({
      variables: {
        filter: {
          ...productFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC',
        },
        ...(filtersCopy && { where: filtersCopy }),
      },
    });
    setProductFilter({
      ...productFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleAddEditProduct = (record) => {
    const otherFilters = { productFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);

    if (record?.id) {
      navigate(`${ROUTES?.PACKAGES}/edit/${record?.id}`, {
        state: {
          stringifyObject,
        },
      });
    } else {
      navigate(`${ROUTES?.PACKAGES}/add`, {
        state: {
          stringifyObject,
        },
      });
    }
  };

  const handleViewProduct = (record) => {
    const otherFilters = { productFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);

    navigate(`${ROUTES?.PACKAGES}/view/${record?.id}`, {
      state: {
        stringifyObject,
      },
    });
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setProductFilter(initialProductFilter);
    setSortedInfo({});
    fetchProductData({
      variables: { filter: initialProductFilter },
    });
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setProductFilter({
        ...productFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchProductData({
        variables: {
          filter: {
            ...productFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    } else {
      setProductFilter({
        ...productFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      fetchProductData({
        variables: {
          filter: {
            ...productFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'industry':
        industryFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              getDBField: 'label',
              justShow: true,
            },
          },
        });
        break;

      case 'lineOfBusiness':
        lineOfBusinessFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              justShow: true,
              getDBField: 'label',
            },
          },
        });
        break;

      case 'subArea':
        subAreaFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              justShow: true,
              getDBField: 'label',
            },
          },
        });
        break;
      case 'sponsorName':
        sponsorFilter({
          variables: {
            filter: {
              sortOn: 'businessName',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: 'businessName',
            },
          },
        });
        break;

      default:
        productFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex,
            },
          },
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };

  const handleChangeAmount = (value, input, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: { ...filtersCopyState?.[dataIndex], [input]: value },
    };

    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex, isPrice = false) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (isPrice) {
        return (
          <div className="custom-filter-dropdown amount-filter-dropdown">
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <PriceComponent
                  value={
                    filtersCopyState?.[dataIndex]
                      ? filtersCopyState?.[dataIndex]?.min
                      : false
                  }
                  onChange={(value) =>
                    handleChangeAmount(value, 'min', dataIndex)
                  }
                  placeholder="min"
                />
              </Col>
              <Col span={12}>
                <PriceComponent
                  value={
                    filtersCopyState?.[dataIndex]
                      ? filtersCopyState?.[dataIndex]?.max
                      : false
                  }
                  onChange={(value) =>
                    handleChangeAmount(value, 'max', dataIndex)
                  }
                  placeholder="max"
                />
              </Col>
            </Row>
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="date-filter-ok"
                htmlType="submit"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'productType' && dataIndex !== 'isActive' && (
              <SearchComponent
                className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
              />
            )}
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {isPrice ? (
                      <span title={formatPrice(item)}>{formatPrice(item)}</span>
                    ) : (
                      <>
                        {dataIndex === 'isActive' ? (
                          <span title={item === true ? 'Active' : 'Inactive'}>
                            {item === true ? 'Active' : 'Inactive'}
                          </span>
                        ) : (
                          <>
                            {dataIndex === 'productQuality' ? (
                              <span title={qualityObjCopy[item]}>
                                {qualityObjCopy[item]}
                              </span>
                            ) : (
                              <span title={item?.label || item?.toString()}>
                                {item?.label || item?.toString()}
                              </span>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div
                    className="filter-checkbox-section"
                    key={item?.key || item?.label || item}
                  >
                    <Checkbox
                      value={item?.key || item?.label || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item?.label || item,
                      )}
                      key={item?.key || item?.label || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      {isPrice ? (
                        <span title={formatPrice(item)}>
                          {formatPrice(item)}
                        </span>
                      ) : (
                        <>
                          {dataIndex === 'isActive' ? (
                            <span title={item === true ? 'Active' : 'Inactive'}>
                              {item === true ? 'Active' : 'Inactive'}
                            </span>
                          ) : (
                            <>
                              {dataIndex === 'productQuality' ? (
                                <span title={qualityObjCopy?.[item]}>
                                  {qualityObjCopy?.[item]}
                                </span>
                              ) : (
                                <span title={item?.label || item?.toString()}>
                                  {item?.label || item?.toString()}
                                </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="common-button discard-button filter-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button filter-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const handleDeleteProduct = async (editProductData) => {
    const response = await deleteProduct({
      variables: { where: { id: editProductData?.id } },
    });
    if (response?.data?.deleteProduct) {
      fetchProductData({
        variables: {
          filter: productFilter,
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const handleProductStatus = async (editProductData) => {
    const editData = {
      uuid: editProductData?.uuid,
      isActive: !editProductData?.isActive,
      title: editProductData?.title,
      productType: editProductData?.productType,
      price: editProductData?.price,
      sku: editProductData?.sku,
      productQuality: editProductData?.productQuality,
      sellingPrice: editProductData?.sellingPrice,
      lineOfBusinessId: editProductData?.lineOfBusinessId,
      subAreaId: editProductData?.subAreaId,
      brands: editProductData?.brands,
      regions: editProductData?.regions,
      industryId: editProductData?.industryId,
    };

    delete editData?.sponsorName;

    const response = await updateProductMutate({
      variables: { data: { ...editData }, where: { id: editProductData?.id } },
    });
    if (response?.data?.updateProduct) {
      fetchProductData({
        variables: {
          filter: productFilter,
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const renderActionButtons = (editProductData) => (
    <div className="d-flex flex-vertical">
      <AccessControl
        allowedPermissions={['FET_PRODUCT_UPDATE', 'FET_PRODUCT_VIEW']}
      >
        <Button
          id="product-table-edit-btn"
          className="b-0"
          onClick={() => handleAddEditProduct(editProductData)}
        >
          Edit
        </Button>
      </AccessControl>
      <AccessControl allowedPermissions={['FET_PRODUCT_VIEW']}>
        <Button
          id="product-table-edit-btn"
          className="b-0"
          onClick={() => handleViewProduct(editProductData)}
        >
          View
        </Button>
      </AccessControl>
      {((editProductData?.sponsorName && isSponsor) ||
        (!editProductData?.sponsorName && !isSponsor)) && (
        <>
          <AccessControl allowedPermissions={['FET_PRODUCT_DELETE']}>
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => handleDeleteProduct(editProductData)}
              okText="Yes"
              cancelText="No"
            >
              <Button id="product-table-status-btn" className="b-0">
                Remove
              </Button>
            </Popconfirm>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_PRODUCT_UPDATE']}>
            <Popconfirm
              title={`Are you sure to ${
                editProductData?.isActive ? 'Mark Inactive' : 'Mark Active'
              }?`}
              onConfirm={() => handleProductStatus(editProductData)}
              okText="Yes"
              cancelText="No"
            >
              <Button id="product-table-status-btn" className="b-0">
                {editProductData?.isActive ? 'Mark Inactive' : 'Mark Active'}
              </Button>
            </Popconfirm>
          </AccessControl>
        </>
      )}
    </div>
  );

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      sorter: true,
      className: 'max-width-column',
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'sku' && sortedInfo?.order,
      width: 120,
    },
    {
      title: 'NAME',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      className: 'max-width-column',
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'title' && sortedInfo?.order,
    },
    !isSponsor && {
      title: 'SPONSOR',
      dataIndex: 'sponsorName',
      key: 'sponsorName',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'sponsorName' && sortedInfo?.order,
      width: 140,
      ...filterPopup('sponsorName'),
      render: (sponsorName) => <span>{sponsorName || '-'}</span>,
    },
    {
      title: 'QUALITY',
      dataIndex: 'productQuality',
      key: 'productQuality',
      sorter: true,
      ellipsis: true,
      sortOrder:
        sortedInfo?.columnKey === 'productQuality' && sortedInfo?.order,
      width: 130,
      ...filterPopup('productQuality'),
      render: (productQuality) => <span>{qualityObjCopy[productQuality]}</span>,
    },
    {
      title: 'TYPE',
      dataIndex: 'productType',
      key: 'productType',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'productType' && sortedInfo?.order,
      width: 100,
      ...filterPopup('productType'),
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industryId',
      key: 'industryId',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'industryId' && sortedInfo?.order,
      width: 190,
      ...filterPopup('industry'),
      render: (industryId, record = {}) => (
        <span>{record?.productIndustry?.label}</span>
      ),
    },
    {
      title: 'LINE OF BUSINESS',
      dataIndex: 'lineOfBusinessId',
      key: 'lineOfBusinessId',
      sorter: true,
      ellipsis: true,
      sortOrder:
        sortedInfo?.columnKey === 'lineOfBusinessId' && sortedInfo?.order,
      width: 190,
      ...filterPopup('lineOfBusiness'),
      render: (lineOfBusinessId, record = {}) => (
        <span>{record?.productLob?.label}</span>
      ),
    },
    {
      title: 'SERVICE TYPE',
      dataIndex: 'subAreaId',
      key: 'subAreaId',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'subAreaId' && sortedInfo?.order,
      width: 160,
      ...filterPopup('subArea'),
      render: (subAreaId, record = {}) => (
        <span>{record?.productSubArea?.label}</span>
      ),
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      align: 'right',
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'price' && sortedInfo?.order,
      width: 150,
      ...filterPopup('price', true),
      render: (price = null) => (
        <span title={formatPrice(price)}>{formatPrice(price)}</span>
      ),
    },
    {
      title: 'PACKAGE PRICE',
      dataIndex: 'sellingPrice',
      key: 'sellingPrice',
      sorter: true,
      align: 'right',
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'sellingPrice' && sortedInfo?.order,
      width: 180,
      ...filterPopup('sellingPrice', true),
      render: (sellingPrice = null) => (
        <span title={formatPrice(sellingPrice)}>
          {formatPrice(sellingPrice)}
        </span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      width: 120,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      },
    },
    checkPermissions(permissions, [
      'FET_PRODUCT_UPDATE',
      'FET_PRODUCT_DELETE',
      'FET_PRODUCT_VIEW',
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => (
        <Popover
          placement="bottom"
          overlayClassName="action-button"
          content={renderActionButtons(record)}
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  const onSearchChange = (value) => {
    setProductFilter({
      ...productFilter,
      skip: value ? 0 : productFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    setPaginationProp({ ...paginationProp, current: 1 });
    fetchProductData({
      variables: {
        filter: {
          ...productFilter,
          skip: value
            ? 0
            : productFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
        ...(filters && { where: filters }),
      },
    });
  };

  const handleExport = async (e) => {
    const { key } = e;
    setExportLoading(true);
    await handleExportCommon({
      filters,
      key,
      selectedKeys,
      module: 'PRODUCT',
    });
    setExportLoading(false);
  };

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="packages"
        folder="PRODUCT"
      />
      <AccessControl allowedPermissions={['FET_PRODUCT_CREATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="product-table-add-btn"
            type="primary"
            onClick={handleAddEditProduct}
          >
            Add Package
          </Button>
        </Portal>
      </AccessControl>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_PRODUCT_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Package name or other detail.."
              name="Products"
              getData={onSearchChange}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </AccessControl>
        </div>
        <div className="header-buttons">
          <AccessControl allowedPermissions={['FET_PRODUCT_IMPORT']}>
            <Button
              size="small"
              className="common-button import-button"
              icon={<img src={ImportIcon} alt="import-icon" width={11} />}
              id="product-table-import-btn"
              type="primary"
              onClick={() => handleShowImportModal(true)}
            >
              Import
            </Button>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_PRODUCT_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              menu={{ items: exportOptions, onClick: handleExport }}
              placement="bottom"
            >
              <Button
                className="common-button export-button"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="product-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl allowedPermissions={['FET_PRODUCT_LIST']} showNoAccess>
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={
                loading || updateProductLoading || deleteProductLoading
              }
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.products?.data || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowSelection={rowSelection}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default ProductTable;
