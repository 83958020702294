import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Popconfirm,
  Popover,
  Tag,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import { AppContext } from '../../../AppContext';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import { exportOptions, ROUTES, SKIP_RECORD } from '../../../common/constants';
import {
  checkPermissions,
  dateFormatWithoutTime,
  formatDate,
  handleExportCommon,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import RangePickerComponent from '../../../components/RangePickerComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import { DELETE_BATCH, UPDATE_BATCH } from '../graphql/Mutations';
import {
  GET_BATCHES,
  INDUSTRY_FILTER,
  LINE_OF_BUSINESS_FILTER,
} from '../graphql/Queries';
import CreateBatchModal from './BatchModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
};

let scrollDebounce = null;

const formInitialValues = {
  isEmailNotificationEnabled: false,
  isSchedule: false,
};
const AssignmentJobsTable = () => {
  const {
    state: { pageSize, globalDateFormat, permissions },
  } = useContext(AppContext);

  const initialBatchFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
      sortOn: 'createdAt',
      sortBy: 'DESC',
    }),
    [pageSize],
  );

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [batchFilter, setBatchFilter] = useState(initialBatchFilter);
  const [filters, setFilters] = useState(null);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [openBatchModal, setOpenBatchModal] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [isEdit, setIsEdit] = useState(false);

  const [updateBatch] = useMutation(UPDATE_BATCH, {
    onError() {},
  });

  const [deleteBatch, { loading: deleteBatchLoading }] = useMutation(
    DELETE_BATCH,
    {
      onError() {},
    },
  );

  const [fetchBatchesData, { loading, data }] = useLazyQuery(GET_BATCHES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.batches?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  const [industryFilters] = useLazyQuery(INDUSTRY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.industryFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [lineOfBusinessFilters] = useLazyQuery(LINE_OF_BUSINESS_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.lineOfBusinessFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const getFilterData = (confirm) => {
    fetchBatchesData({
      variables: {
        filter: { ...batchFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState }),
      },
    });
    setFilters(filtersCopyState);
    setBatchFilter({
      ...batchFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);

      switch (filterIndex) {
        case 'createdAt':
          // no api call for range filter;
          break;

        case 'industry':
          industryFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                getDBField: 'label',
                justShow: true,
              },
            },
          });
          break;

        case 'lineOfBusiness':
          lineOfBusinessFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                getDBField: 'label',
              },
            },
          });
          break;

        default:
          // add default query here
          break;
      }
    }
    if (!filterVisible) {
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  useEffect(() => {
    const permitted = checkPermissions(permissions, [
      'FET_DATA_ASSIGNMENT_LIST',
    ]);
    const whereFilter = location?.state;
    let parsedObject;

    if (!isEmpty(whereFilter)) {
      parsedObject = JSON?.parse(whereFilter?.stringifyObject);
    }

    if (permitted) {
      fetchBatchesData({
        variables: {
          filter: parsedObject?.batchFilter ?? batchFilter,
          ...(parsedObject && { where: parsedObject?.filters }),
        },
      });
      if (!isEmpty(parsedObject)) {
        setFilters({ ...parsedObject?.filters });
        setFiltersCopyState({
          ...parsedObject?.filters,
        });
        setSearchValue(parsedObject?.batchFilter?.search);
        const sorter = {
          order:
            parsedObject?.batchFilter?.sortBy === 'ASC' ? 'ascend' : 'descend',
          columnKey: parsedObject?.batchFilter?.sortOn,
        };
        setSortedInfo({ ...sorter });
        setBatchFilter({ ...parsedObject?.batchFilter });
        setPaginationProp({ ...parsedObject?.paginationProp });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetch = () => {
    fetchBatchesData({
      variables: {
        filter: batchFilter,
        ...(filters && { where: filters }),
      },
    });
  };
  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'industry':
            industryFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  getDBField: 'label',
                  justShow: true,
                },
              },
            });
            break;

          case 'lineOfBusiness':
            lineOfBusinessFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  justShow: true,
                  getDBField: 'label',
                },
              },
            });
            break;

          default:
            // don't have any default filter query
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: moment(values?.[0])?.startOf('day'),
      to: moment(values?.[1])?.endOf('day'),
    };

    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete filtersCopy?.[dataIndex];
    }
    setFiltersCopyState(filtersCopy);
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];

    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleReset = (clearFilters, dataIndex) => {
    let filtersCopy = {
      ...filters,
    };
    if (dataIndex === 'createdAt') {
      delete filtersCopy?.createdAt;
    } else {
      filtersCopy = {
        ...filters,
        [dataIndex]: [],
      };
    }

    setFilters(filtersCopy);
    setFiltersCopyState(filtersCopy);

    fetchBatchesData({
      variables: {
        filter: {
          ...batchFilter,
          skip: 0,
          sortOn: 'name',
          sortBy: 'DESC',
        },
        ...(filtersCopy && { where: filtersCopy }),
      },
    });

    setBatchFilter({
      ...batchFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'industry':
        industryFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              getDBField: 'label',
              justShow: true,
            },
          },
        });
        break;

      case 'lineOfBusiness':
        lineOfBusinessFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              justShow: true,
              getDBField: 'label',
            },
          },
        });
        break;

      default:
        // add default query
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown range-filter-dropdown">
            <RangePickerComponent
              format={dateFormatWithoutTime(globalDateFormat)}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                filtersCopyState?.[dataIndex]?.from
                  ? [
                      moment(filtersCopyState?.[dataIndex]?.from, 'DD/MM/YYYY'),
                      moment(filtersCopyState?.[dataIndex]?.to, 'DD/MM/YYYY'),
                    ]
                  : []
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="date-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'isActive' && dataIndex !== 'createdAt' && (
              <SearchComponent
                className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
              />
            )}
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'createdAt' ? (
                      <span title={item?.label || item?.toString()}>
                        {moment(item?.label || item?.toString())?.format('L')}
                      </span>
                    ) : (
                      <>
                        {dataIndex === 'isActive' ? (
                          <span title={item === true ? 'Active' : 'Inactive'}>
                            {item === true ? 'Active' : 'Inactive'}
                          </span>
                        ) : (
                          <span title={item?.label || item?.toString()}>
                            {item?.label || item?.toString()}
                          </span>
                        )}
                      </>
                    )}
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div
                    className="filter-checkbox-section"
                    key={item?.key || item?.label || item}
                  >
                    <Checkbox
                      value={item?.key || item?.label || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item?.label || item,
                      )}
                      key={item?.key || item?.label || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      {dataIndex === 'createdAt' ? (
                        <span title={item?.label || item?.toString()}>
                          {moment(item?.label || item?.toString())?.format('L')}
                        </span>
                      ) : (
                        <>
                          {dataIndex === 'isActive' ? (
                            <span title={item === true ? 'Active' : 'Inactive'}>
                              {item === true ? 'Active' : 'Inactive'}
                            </span>
                          ) : (
                            <span title={item?.label || item?.toString()}>
                              {item?.label || item?.toString()}
                            </span>
                          )}
                        </>
                      )}
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="common-button discard-button filter-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button filter-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const onSearchChange = (value) => {
    setBatchFilter({
      ...batchFilter,
      skip: value ? 0 : batchFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    setPaginationProp({ ...paginationProp, current: 1 });
    fetchBatchesData({
      variables: {
        filter: {
          ...batchFilter,
          skip: value ? 0 : batchFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
        ...(filters && { where: filters }),
      },
    });
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setBatchFilter({
        ...batchFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchBatchesData({
        variables: {
          filter: {
            ...batchFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    } else {
      setBatchFilter({
        ...batchFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'name',
        sortBy: 'DESC',
      });
      fetchBatchesData({
        variables: {
          filter: {
            ...batchFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'name',
            sortBy: 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const handleExport = async (e) => {
    const { key } = e;
    setExportLoading(true);
    await handleExportCommon({
      filters,
      key,
      selectedKeys,
      module: 'BATCH',
    });
    setExportLoading(false);
  };

  const handleEditBatch = (record) => {
    setInitialValues({
      ...record,
      ...(record?.scheduleDate && {
        scheduleDate: moment(record?.scheduleDate),
      }),
    });
    setEmailData(record?.emailNotificationConfig || []);
    setIsEdit(true);
    setPopoverOpen(false);
    setOpenBatchModal(true);
  };

  const handleDeleteBatch = async (editBatchData) => {
    const response = await deleteBatch({
      variables: { where: { id: editBatchData?.id } },
    });
    if (response?.data?.deleteBatch) {
      fetchBatchesData({
        variables: {
          filter: batchFilter,
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const handleAddData = (editBatchData) => {
    const otherFilters = { batchFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);
    navigate(
      `${ROUTES?.ASSIGNMENT_JOBS}/edit/${editBatchData?.id}${ROUTES?.ENTITY_FILTERS}`,
      {
        state: {
          stringifyObject,
          batchName: editBatchData?.name,
        },
      },
    );
  };

  const handleScheduleBatch = async (record) => {
    const {
      name = '',
      description = '',
      industryId = null,
      lineOfBusinessId = null,
      tenantIds = [],
      isSchedule = false,
      isEmailNotificationEnabled = false,
      emailNotificationConfig = [],
    } = record;

    try {
      const response = await updateBatch({
        variables: {
          data: {
            ...(record?.scheduleDate && {
              scheduleDate: moment(record?.scheduleDate),
            }),
            name,
            description,
            industryId,
            lineOfBusinessId,
            tenantIds,
            isSchedule,
            isEmailNotificationEnabled,
            emailNotificationConfig,
            status: 'SCHEDULED',
          },
          where: { id: record?.id },
        },
      });
      if (response?.data?.updateBatch) {
        fetchBatchesData({
          variables: {
            filter: batchFilter,
            ...(filters && { where: filters }),
          },
        });
      }
    } catch (error) {
      return error;
    }
  };

  const renderActionButtons = (editBatchData) => (
    <div className="d-flex flex-vertical">
      {editBatchData?.pickListStatus?.key === 'UNSCHEDULED' && (
        <AccessControl
          allowedPermissions={[
            'FET_DATA_ASSIGNMENT_UPDATE',
            'FET_DATA_ASSIGNMENT_VIEW',
          ]}
        >
          <Popconfirm
            title="Are you sure to schedule?"
            onConfirm={() => handleScheduleBatch(editBatchData)}
            okText="Yes"
            cancelText="No"
            placement="topLeft"
          >
            <Button id="product-table-edit-btn" className="b-0">
              Schedule
            </Button>
          </Popconfirm>
        </AccessControl>
      )}

      <AccessControl
        allowedPermissions={[
          'FET_DATA_ASSIGNMENT_UPDATE',
          'FET_DATA_ASSIGNMENT_VIEW',
        ]}
      >
        <Button
          id="product-table-edit-btn"
          className="b-0"
          onClick={() => handleEditBatch(editBatchData)}
        >
          Setting
        </Button>
      </AccessControl>
      <AccessControl
        allowedPermissions={[
          'FET_DATA_ASSIGNMENT_UPDATE',
          'FET_DATA_ASSIGNMENT_LIST',
        ]}
      >
        <Button
          id="product-table-edit-btn"
          className="b-0"
          onClick={() => handleAddData(editBatchData)}
        >
          {editBatchData?.pickListStatus?.key === 'UNSCHEDULED'
            ? 'Add Data'
            : 'View Data'}
        </Button>
      </AccessControl>
      <AccessControl allowedPermissions={['FET_DATA_ASSIGNMENT_DELETE']}>
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => handleDeleteBatch(editBatchData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="batch-table-status-btn" className="b-0">
            Remove
          </Button>
        </Popconfirm>
      </AccessControl>
    </div>
  );

  const columns = [
    {
      title: 'BATCH ID',
      dataIndex: 'batchCode',
      key: 'batchCode',
      sorter: true,
      ellipsis: true,
      fixed: 'left',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'batchCode' && sortedInfo?.order,
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industryId',
      key: 'industryId',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      width: 200,
      ...filterPopup('industry'),
      sortOrder: sortedInfo?.columnKey === 'industryId' && sortedInfo?.order,
      render: (industryId, record = {}) => (
        <span>{record?.industry?.label}</span>
      ),
    },
    {
      title: 'LINE OF BUSINESS',
      dataIndex: 'lineOfBusinessId',
      key: 'lineOfBusinessId',
      sorter: true,
      ellipsis: true,
      width: 200,
      className: 'max-width-column',
      ...filterPopup('lineOfBusiness'),
      sortOrder:
        sortedInfo?.columnKey === 'lineOfBusinessId' && sortedInfo?.order,
      render: (lineOfBusinessId, record = {}) => (
        <span>{record?.lineOfBusiness?.label}</span>
      ),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      width: 150,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
    },
    {
      title: 'ADD ON',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      ellipsis: true,
      width: 180,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (createdAt) => (
        <span>{formatDate(createdAt, globalDateFormat) || '-'}</span>
      ),
    },
    {
      title: 'STARTED',
      dataIndex: 'started',
      key: 'started',
      sorter: true,
      ellipsis: true,
      width: 180,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'started' && sortedInfo?.order,
      render: (started) => (
        <span>{formatDate(started, globalDateFormat) || '-'}</span>
      ),
    },
    {
      title: 'COMPLETED',
      dataIndex: 'completed',
      key: 'completed',
      sorter: true,
      ellipsis: true,
      width: 180,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'completed' && sortedInfo?.order,
      render: (completed) => (
        <span>{formatDate(completed, globalDateFormat) || '-'}</span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      ellipsis: true,
      width: 150,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
      render: (status, record = {}) => (
        <span>{record?.pickListStatus?.label}</span>
      ),
    },
    checkPermissions(permissions, [
      'FET_DATA_ASSIGNMENT_UPDATE',
      'FET_DATA_ASSIGNMENT_DELETE',
      'FET_DATA_ASSIGNMENT_VIEW',
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => (
        <Popover
          placement="bottomLeft"
          overlayClassName="action-button"
          trigger="hover"
          content={renderActionButtons(record)}
          open={popoverOpen === record?.id} // Show Popover only for the current row
          onOpenChange={(visible) =>
            setPopoverOpen(visible ? record?.id : null)
          }
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id,
      });
    },
  };

  return (
    <div>
      {openBatchModal && (
        <CreateBatchModal
          showModal={openBatchModal}
          setShowModal={setOpenBatchModal}
          initialValues={initialValues}
          emailData={emailData}
          setEmailData={setEmailData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          refetch={refetch}
        />
      )}
      <AccessControl
        allowedPermissions={[
          'FET_DATA_ASSIGNMENT_CREATE',
          'FET_DATA_ASSIGNMENT_UPDATE',
        ]}
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="product-table-add-btn"
            type="primary"
            onClick={() => {
              setInitialValues({ ...formInitialValues });
              setEmailData([]);
              setIsEdit(false);
              setOpenBatchModal(true);
            }}
          >
            Add Batch
          </Button>
        </Portal>
      </AccessControl>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_DATA_ASSIGNMENT_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Search name or other detail.."
              name="Products"
              getData={onSearchChange}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </AccessControl>
        </div>
        <div className="header-buttons">
          <AccessControl allowedPermissions={['FET_DATA_ASSIGNMENT_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              menu={{ items: exportOptions, onClick: handleExport }}
              placement="bottom"
            >
              <Button
                className="common-button data-assignment-export-btn ml-8"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="product-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl
        allowedPermissions={['FET_DATA_ASSIGNMENT_LIST']}
        showNoAccess
      >
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={loading || deleteBatchLoading}
              columns={columns}
              data={data?.batches?.data || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowSelection={rowSelection}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default AssignmentJobsTable;
